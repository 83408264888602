import MainHeader from '../../components/mainHeader/Index.vue'
import MainFooter from '../../components/mainFooter/Index.vue'
import Dict from '../../components/dict/Index.vue'
import { savePostJob } from '@/api/job';
export default {
  components: { MainHeader, MainFooter, Dict },
  data(){
    return{
      jobPostForm:{},
      rules: {
        title: [
          { required: true, message: 'Please input Job Title', trigger: 'blur' },
          { max: 50,message: "Maximum 50 characters" },
          // {validator: this.checkEnglish}
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        company: [
          { required: true, message: 'Please input Company', trigger: 'blur' },
          { max: 50,message: "Maximum 50 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        website: [
          { required: true, message: 'Please input Website', trigger: 'blur' },
          { max: 50,message: "Maximum 50 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        contact: [
          { required: true, message: 'Please input Contact', trigger: 'blur' },
          { max: 50,message: "Maximum 50 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        type: [
          { required: true, message: 'Please select Job Type', trigger: 'change' }
        ],
        experienceLevel: [
          { required: true, message: 'Please select ExperienceLevel', trigger: 'change' }
        ],
        location: [
          { required: true, message: 'Please input Location', trigger: 'blur' },
          { max: 50,message: "Maximum 50 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        description: [
          { required: true, message: 'Please input Job Description', trigger: 'blur' },
          { max: 8000,message: "Maximum 8000 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        requirement: [
          { required: true, message: 'Please input Requirement', trigger: 'blur' },
          { max: 8000,message: "Maximum 8000 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        salaryStart: [
          {pattern: /^(0|[1-9][0-9]*)$/,message:'Invalid number', trigger: 'blur'}
        ],
        salaryEnd: [
          {pattern: /^(0|[1-9][0-9]*)$/,message:'Invalid number', trigger: 'blur'}
        ],
        tokenStart: [
          {pattern: /^(0|[1-9][0-9]*)$/,message:'Invalid number', trigger: 'blur'}
        ],
        tokenEnd: [
          {pattern: /^(0|[1-9][0-9]*)$/,message:'Invalid number', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    checkEnglish(rule, value, callback){
      let reg = /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/;
      let n = reg.test(value);
      if ( n ) {
        callback(new Error("Including invalid characters, English words only."))
        
      }
      else{
        callback();
        
      }
    },
    toRouter(path){
      this.$router.push({name:path})
    },
    savePostJob(event){
      const target = event.currentTarget
      if(event.currentTarget.disabled)
        return
      event.currentTarget.disabled=true
      const loading = this.$loading({
        target: event.currentTarget,
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const that = this
      this.$refs['jobPostForm'].validate((valid) => {
        if (valid) {
          that.jobPostForm.cnxtToken = window.localStorage.getItem("LOGIN_ACCOUNT_ADDRESS");
          savePostJob(that.jobPostForm).then((resp)=>{
            that.jobPostForm.id = resp.data;
            target.disabled=false
            loading.close();
            that.$router.push({name:'jobs'})
            // that.$message({
            //   message: 'Save post job success!',
            //   type: 'success',
            //   showClose: true
            // })
          })
        } else {
          target.disabled=false
          loading.close();
          console.log('error submit!!');
          return false;
        }
      });
      
    }
  }
};